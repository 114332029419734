






















import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

@Component({
  metaInfo: {
    title: "Yönetim Konsolu"
  }
})
export default class AdminHome extends Vue {
  userCount = 0;
  courseRegs = [
    {
      name: "",
      total: 0,
      completed: 0
    }
  ];

  async getUserCount() {
    const usersQs = await firebase
      .firestore()
      .collection("users")
      .get();

    this.userCount = usersQs.size;
  }

  async getUserCountOfCourses() {
    const courseQs = await firebase
      .firestore()
      .collection("courses")
      .orderBy("name")
      .get();

    this.courseRegs = [];

    courseQs.forEach(async course => {
      const courseRef = await firebase
        .firestore()
        .doc(`courses/${course.id}`)
        .get();

      const regs = firebase.firestore().collection("courseRegistrations");

      const totalRegs = await regs.where("courseId", "==", course.id).get();
      const completedRegs = await regs
        .where("courseId", "==", course.id)
        .where("progress", ">=", 99)
        .get();

      this.courseRegs.push({
        name: courseRef.data()?.name,
        total: totalRegs.size,
        completed: completedRegs.size
      });
    });
  }

  async mounted() {
    this.getUserCount();
    this.getUserCountOfCourses();
  }
}
